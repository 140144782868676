.backdrop-loader {
  position: absolute;
  z-index: 111111;
  height: 98lvh;
  width: 100%;
  top: 0;
  backdrop-filter: blur(2px); // brightness(0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.on-body {
  position: absolute;
  z-index: 111111;
  top: 0;
}
.non-backdrop-loader {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
