.player-wrapper {
  &.player-wrapper-init {
    .react-player {
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      video {
        // width: 300px !important;
        height: min(35vw, 600px) !important;
        width: auto !important;
        object-fit: contain;
        border-radius: 16px;
        aspect-ratio: 9 / 16;
      }
    }
  }
  &.player-wrapper-ready {
    .react-player {
      display: flex;
      justify-content: center;
      align-items: center;
      video {
        // width: 300px !important;
        min-height: 200px !important;
        width: auto !important;
        object-fit: contain;
        max-height: min(35vw, 600px) !important;
        border-radius: 16px;
        max-width: 20vw !important;
      }
    }
  }
}

.rhap_container {
  border-radius: 8px;
  .rhap_main {
    filter: brightness(0.7);
  }
}

[data-bs-theme='dark'] {
  .rhap_container {
    filter: invert(0.9);
  }
}

.nav-item-wrapper {
  border-bottom: 1px solid #ecebebcc;
}

@media (min-width: 992px) {
  .navbar-vertical.navbar.navbar-expand-lg {
    .nav-item-wrapper {
      border-bottom: none;
    }
  }
  html:not(.navbar-vertical-collapsed) {
    .navbar-vertical.navbar.navbar-expand-lg {
      width: 11rem;
      .navbar-vertical-footer {
        width: 11rem !important;
      }
    }
    .container-fluid .content {
      margin-left: 11rem !important;
    }
    .navbar-vertical.navbar-expand-lg
      .navbar-vertical-content
      .navbar-nav
      .nav-link {
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 0.75rem;
    }

    .nav-link-text-wrapper {
      // margin-top: 3px;
    }
    .navbar-vertical .navbar-vertical-content .nav-link-text {
      display: inline-block;
    }
  }
}
