.copy-shareable-link {
  label {
    opacity: 0;
  }
  &:hover {
    label {
      opacity: 1;
    }
  }
}
