.sticky-column {
  position: sticky;
  right: 0;
  background-color: white !important; /* Optional: adjust as needed for visibility */
  z-index: 1;
}
[data-bs-theme='dark'] {
  .sticky-column {
    background-color: #141824 !important;
  }
}
